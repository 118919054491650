import React, { useState, useEffect, useRef } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebaseConfig"; // Import Firebase config
import Main from "./Main";

// Images
import RasporedTreningaBg from "../../../Assets/Images/Ostalo/Trening.jpg";

// Static team order array
const teamOrder = [
  "Seniori",
  "Juniori",
  "Kadeti",
  "Pioniri",
  "Limači",
  "Zagići",
];

export default function RasporedTreninga() {
  const [trainings, setTrainings] = useState([]);
  const trainingRefs = useRef({}); // Create refs for each training component

  // Fetch training data from Firebase
  useEffect(() => {
    const fetchTrainings = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "trening"));
        const fetchedTrainings = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort trainings by predefined team order
        const sortedTrainings = fetchedTrainings.sort(
          (a, b) =>
            teamOrder.indexOf(a.selection) - teamOrder.indexOf(b.selection)
        );

        setTrainings(sortedTrainings);
      } catch (error) {
        // Error handling if needed
      }
    };

    fetchTrainings();
    document.title = "NK Podsused | Raspored treninga";
  }, []);

  return (
    <>
      <Main
        desktopBackground={RasporedTreningaBg}
        phoneBackground={RasporedTreningaBg}
        title="RASPORED TRENINGA"
      />

      <div className="section raspored-treninga">
        {/* Conditionally display "No trainings" message or list of trainings */}
        {trainings.length === 0 ? (
          <div className="info-box">
            <p>Trenutno nema spremljenih treninga.</p>
            <span>NK Podsused</span>
          </div>
        ) : (
          trainings.map((training) => (
            <div
              key={training.id}
              ref={(el) => (trainingRefs.current[training.selection] = el)} // Assign ref based on selection
            >
              <Trening
                momcad={training.selection}
                dan1={training.days[0]?.day}
                sati1={training.days[0]?.time}
                dan2={training.days[1]?.day}
                sati2={training.days[1]?.time}
                dan3={training.days[2]?.day}
                sati3={training.days[2]?.time}
                trener={training.trainerName}
                number={training.trainerContact}
              />
            </div>
          ))
        )}
      </div>
    </>
  );
}

function Trening(props) {
  return (
    <div className="trening">
      <div className="trening-grid">
        <h2>{props.momcad}</h2>
        <div className="trening-box">
          <h4>{props.dan1}</h4>
          <p>{props.sati1}</p>
        </div>
        {props.dan2 && props.sati2 && (
          <div className="trening-box">
            <h4>{props.dan2}</h4>
            <p>{props.sati2}</p>
          </div>
        )}
        {props.dan3 && props.sati3 && (
          <div className="trening-box">
            <h4>{props.dan3}</h4>
            <p>{props.sati3}</p>
          </div>
        )}
      </div>
      <div className="trening-info">
        <h5>TRENER: {props.trener}</h5>
        <a href={`tel:${props.number}`}>
          <i className="fi fi-rs-phone-flip"></i> {props.number}
        </a>
      </div>
    </div>
  );
}
