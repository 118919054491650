import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import PlavaBG from "../../../Assets/Images/Ostalo/Plava-bg.jpg";
import { Link } from "react-router-dom";
import Main from "../../Pages/Home/Main";

export default function AdminDashboard() {
  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="ADMIN"
      />
      <div className="section">
        <div className="home-grid">
          <div className="home-btn">
            <img src={PlavaBG} alt="NK Podsued" />
            <Link
              to="/admin/dodaj-novost"
              onClick={() => window.scrollTo(0, 0)}
            >
              DODAJ NOVOST
            </Link>
          </div>
          <div className="home-btn">
            <img src={PlavaBG} alt="NK Podsued" />
            <Link
              to="/admin/dodaj-utakmice-seniori"
              onClick={() => window.scrollTo(0, 0)}
            >
              DODAJ UTAKMICE SENIORA
            </Link>
          </div>

          <div className="home-btn">
            <img src={PlavaBG} alt="NK Podsued" />
            <Link
              to="/admin/dodaj-ostale-utakmice"
              onClick={() => window.scrollTo(0, 0)}
            >
              DODAJ OSTALE UTAKMICE
            </Link>
          </div>

          <div className="home-btn">
            <img src={PlavaBG} alt="NK Podsued" />
            <Link
              to="/admin/dodaj-galeriju"
              onClick={() => window.scrollTo(0, 0)}
            >
              DODAJ GALERIJU
            </Link>
          </div>
          <div className="home-btn">
            <img src={PlavaBG} alt="NK Podsued" />
            <Link
              to="/admin/dodaj-strijelce"
              onClick={() => window.scrollTo(0, 0)}
            >
              DODAJ STRIJELCE
            </Link>
          </div>
          <div className="home-btn">
            <img src={PlavaBG} alt="NK Podsued" />
            <Link
              to="/admin/uredi-tablicu"
              onClick={() => window.scrollTo(0, 0)}
            >
              UREDI TABLICU
            </Link>
          </div>
          <div className="home-btn">
            <img src={PlavaBG} alt="NK Podsued" />
            <Link
              to="/admin/uredi-treninge"
              onClick={() => window.scrollTo(0, 0)}
            >
              UREDI TRENINGE
            </Link>
          </div>

          <div className="home-btn">
            <img src={PlavaBG} alt="NK Podsued" />
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              NASLOVNA
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
