import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

// Images
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

// Components
import Main from "./Main";
import Dresovi from "./Dresovi";
import Matches from "./Matches"; // Matches component
import News from "../News/News";
import Standings from "./Standings";
import MemberHome from "./MemberHome";
import Statistics from "./Statistics";

export default function Home() {
  const [matches, setMatches] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "NK Podsused";

    const fetchMatches = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "utakmiceSeniori"));
        const matchList = [];
        querySnapshot.forEach((doc) => {
          const match = { id: doc.id, ...doc.data() };
          matchList.push(match);
        });

        // Sort by date in descending order
        const sortedMatches = matchList.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );

        // Take the last two matches
        const latestMatches = sortedMatches.slice(0, 2);

        setMatches(latestMatches); // Set only the last two matches
      } catch (error) {
        console.error("Error fetching matches:", error);
      }
    };

    const fetchNewsData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "novosti"));
        const newsList = [];
        querySnapshot.forEach((doc) => {
          newsList.push({ id: doc.id, ...doc.data() });
        });
        setNewsData(newsList);
      } catch (error) {
        console.error("Error fetching news data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMatches(); // Fetch only the last two matches
    fetchNewsData(); // Fetch news
  }, []);

  // Sort and fetch the latest news
  const sortedNews = [...newsData].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  // Get the last two news items
  const latestNews = sortedNews.slice(0, 2);

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title=""
      />
      <div className="section">
        <div className="season-matches-top">
          <Matches matches={matches} />{" "}
          {/* Display only the last two matches */}
          <Link
            to="/utakmice"
            className="btn utakmice-btn"
            onClick={() => window.scrollTo(0, 0)}
          >
            Ostale utakmice
          </Link>
        </div>

        <Dresovi />
        <h2>NOVOSTI</h2>
        <div className="grid-2">
          {loading ? (
            <div>Učitavanje novosti...</div>
          ) : (
            latestNews.map((newsItem) => (
              <News key={newsItem.id} slug={newsItem.slug} />
            ))
          )}
        </div>
        <div className="news-btn">
          <Link
            to="/novosti"
            className="btn"
            onClick={() => window.scrollTo(0, 0)}
          >
            Ostale novosti...
          </Link>
        </div>
        <div className="light-border"></div>

        <h2>SEZONA 2024/2025</h2>
        <h5 className="league-title">2. ZAGREBAČKA LIGA</h5>
        <Standings />

        <div className="light-border"></div>

        <h2>STRIJELCI NK PODSUSED</h2>
        <h2>SENIORI</h2>
        <h5 className="league-title">SEZONA 2024/2025</h5>
        <Statistics />

        <div className="light-border"></div>
        <MemberHome />
      </div>
    </>
  );
}
