import React, { useEffect, useState } from "react";
import NoPlayer from "../../../Assets/Images/Selekcije/Seniori/No-Player.jpg";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import Main from "../Home/Main";
import { Link } from "react-router-dom";

export default function Seniori() {
  const [showImage, setShowImage] = useState("");
  const [playerInfo, setPlayerInfo] = useState(null);

  const openImage = (imageSrc, info) => {
    setShowImage(imageSrc);
    setPlayerInfo(info);
  };

  const closeImage = () => {
    setShowImage("");
    setPlayerInfo(null);
  };

  // Scroll to top of the page
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    document.title = "NK Podsused | Seniori";
  }, []);
  const preventContextMenu = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="SENIORI"
      />
      <div className="section seniori">
        <div className="podsused-team">
          {/* *****NAPADAČI***** */}
          <h3>NAPADAČI</h3>
          <div className="team-grid">
            <Players
              image={NoPlayer}
              name="Tomislav Posavec"
              number="37"
              info={{
                birth: "22.05.2004",
                position: "Napadač",
                foot: "Desna",
              }}
              openImage={openImage}
            />
            {/*    <Players
              image={NoPlayer}
              name="Luka Turčić"
              number="16"
              info={{
                birth: "15.03.1997",
                position: "Napadač",
                foot: "Desna",
              }}
              openImage={openImage}
            /> */}

            <Players
              image={NoPlayer}
              name="Jan Mesić"
              number="30"
              info={{
                birth: "21.05.2004",
                position: "Lijevo Krilo",
                foot: "Desna",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Leon Nerat"
              number="19"
              info={{
                birth: "06.09.1999",
                position: "Desno krilo",
                foot: "Lijeva",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Filip Đuričić"
              number="16"
              info={{
                birth: "31.03.2004",
                position: "Desno krilo",
                foot: "Lijeva",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Jan Jurić"
              number="11"
              info={{
                birth: "22.05.2003",
                position: "Napadač",
                foot: "Desna",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Josip Jambrek"
              number="7"
              info={{
                birth: "12.06.2004",
                position: "Lijevo krilo",
                foot: "Lijeva",
              }}
              openImage={openImage}
            />
          </div>
          {/* *****VEZNI RED***** */}
          <h3>VEZNI IGRAČI</h3>
          <div className="team-grid">
            <Players
              image={NoPlayer}
              name="Domagoj Mihoković"
              number="37"
              info={{
                birth: "23.08.1997",
                position: "Vezni igrač",
                foot: "Lijeva",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Marin Knežević"
              number="26"
              info={{
                birth: "15.12.2000",
                position: "Vezni igrač",
                foot: "Desna",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Stipe Miloš"
              number="22"
              info={{
                birth: "15.02.1990",
                position: "Vezni igrač",
                foot: "Desna",
              }}
              openImage={openImage}
            />

            <Players
              image={NoPlayer}
              name="Martin Sedmak"
              number="15"
              info={{
                birth: "30.08.1992",
                position: "Vezni igrač",
                foot: "Desna",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Kristian Mihelčić"
              number="13"
              info={{
                birth: "21.08.1994",
                position: "Vezni igrač",
                foot: "Desna",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="C | Josip Gotal"
              number="10"
              info={{
                birth: "12.09.1990",
                position: "Vezni igrač",
                foot: "Desna",
              }}
              openImage={openImage}
            />
          </div>
          {/* *****OBRANA***** */}
          <h3>OBRANA</h3>
          <div className="team-grid">
            {/*   <Players
              image={NoPlayer}
              name="Marko Hojsak"
              number="5"
              info={{
                birth: "30.12.1993",
                position: "Desni bek",
                foot: "Lijeva",
              }}
              openImage={openImage}
            /> */}
            <Players
              image={NoPlayer}
              name="Marijan Vodopija"
              number="21"
              info={{
                birth: "11.03.1992",
                position: "Lijevi bek",
                foot: "Desna",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Karlo Borko"
              number="18"
              info={{
                birth: "30.09.19927",
                position: "Stoper",
                foot: "Desna",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Roko Budiselić"
              number="14"
              info={{
                birth: "14.09.2005",
                position: "Desni bek",
                foot: "Desna",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Matija Stunja"
              number="8"
              info={{
                birth: "19.02.1995",
                position: "Lijevi bek",
                foot: "Lijeva",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Marko Perković"
              number="6"
              info={{
                birth: "17.11.2000",
                position: "Stoper",
                foot: "Desna",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Luka Moguljak"
              number="5"
              info={{
                birth: "21.08.1993",
                position: "Stoper",
                foot: "Desna",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Kristian Minković"
              number="3"
              info={{
                birth: "29.03.1996",
                position: "Lijevo Krilo",
                foot: "Desna",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Antonio Boras"
              number="2"
              info={{
                birth: "23.01.1997",
                position: "Lijevi bek",
                foot: "Lijeva",
              }}
              openImage={openImage}
            />
          </div>
          {/* *****GOLMANI***** */}
          <h3>GOLMANI</h3>
          <div className="team-grid">
            <Players
              image={NoPlayer}
              name="Teo Ivanuš"
              number="1"
              info={{
                birth: "05.08.2000",
                position: "Golman",
                foot: "Lijeva",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Goran Bašić"
              number="12"
              info={{
                birth: "25.05.1988",
                position: "Golman",
                foot: "Desna",
              }}
              openImage={openImage}
            />
          </div>
          {/* *****TRENER***** */}
          <h3>TRENER</h3>
          <div className="team-grid">
            <Coach image={NoPlayer} name="Marko Žabić" />
          </div>
        </div>
        <Link className="btn" to="/utakmice" onClick={() => handleClick("/")}>
          Utakmice &rarr;
        </Link>
      </div>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div className="overlay overlay-player" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={
                ({ width: "100%", height: "100%" }, { userSelect: "none" })
              }
              onContextMenu={preventContextMenu}
              alt="Show"
            />
            <div className="player-info">
              <h5>Datum rođenja:</h5>
              <p> {playerInfo.birth}</p>
              <h5>Pozicija:</h5>
              <p>{playerInfo.position}</p>
              <h5>Jača noga:</h5>
              <p>{playerInfo.foot}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function Players({ image, name, number, info, openImage }) {
  const handleClick = () => {
    openImage(image, info);
  };
  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div className="player-box" onClick={handleClick}>
      <img
        src={image}
        alt={name}
        onContextMenu={preventContextMenu}
        style={{ userSelect: "none" }}
      />
      <h3>{name}</h3>
      <p>{number}</p>
    </div>
  );
}

function Coach(props) {
  const preventContextMenu = (e) => {
    e.preventDefault();
  };
  return (
    <div className="player-box">
      <img
        src={props.image}
        alt={props.name}
        onContextMenu={preventContextMenu}
        style={{ userSelect: "none" }}
      />
      <h3>{props.name}</h3>
    </div>
  );
}
