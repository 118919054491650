import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./Styles/App.scss";
import {
  Navigation,
  Home,
  Footer,
  Seniori,
  About,
  Season,
  Kadeti,
  MladiPioniri,
  Limaci,
  Zagici,
  Pioniri,
  Contact,
  PrivacyPolicy,
  TermsOfUse,
  Gallery,
  BackToTopButton,
  Quiz,
  RasporedTreninga,
  Veterani,
  OstaloSeason,
  ErrorPage,
  LoadingScreen,
  NewsDetails,
  NewsPages,
  Oprema,
  Member,
  Donations,
  AddNews,
  Login,
  AddMatches,
  AddGallery,
  AddPlayers,
  AddStandings,
  AddSelections,
  AddTrainings,
} from "./Components";
import Sponsors from "./Components/Pages/Home/Sponsors";
import NotFoundPage from "./Components/Partials/Errors/NotFoundPage";
import AdminDashboard from "./Components/Admin/Home/AdminDashboard";
import PrivateRoute from "./Components/Admin/LogInOut/PrivateRoute";
import AuthProvider from "./Components/Admin/LogInOut/AuthProvider";

const App = () => {
  const [loadingComplete, setLoadingComplete] = useState(false);

  return (
    <AuthProvider>
      <BrowserRouter>
        <LoadingScreen setLoadingComplete={() => setLoadingComplete(true)} />
        {loadingComplete && (
          <>
            <Navigation />
            <BackToTopButton />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/raspored-treninga" element={<RasporedTreninga />} />
              {/* SEASON */}
              <Route path="/seniori" element={<Seniori />} />
              <Route path="/ostale-utakmice" element={<OstaloSeason />} />
              {/* SEASON */}
              <Route path="/utakmice" element={<Season />} />
              <Route path="/ostale-utakmice" element={<OstaloSeason />} />
              {/* ABOUT */}
              <Route path="/o-klubu" element={<About />} />
              {/* SELECTIONS */}
              <Route path="/veterani" element={<Veterani />} />
              <Route path="/kadeti" element={<Kadeti />} />
              <Route path="/mlađi-pioniri" element={<MladiPioniri />} />
              <Route path="/pioniri" element={<Pioniri />} />
              <Route path="/limači" element={<Limaci />} />
              <Route path="/zagići" element={<Zagici />} />
              {/* OPREMA */}
              <Route path="/oprema" element={<Oprema />} />
              {/* MEMBER */}
              <Route path="/postani-član" element={<Member />} />
              {/* DONATIONS */}
              <Route path="/donacije" element={<Donations />} />
              {/* NOVOSTI */}
              <Route path="/kviz" element={<Quiz />} />
              {/* NEWS */}
              <Route path="/novosti" element={<NewsPages />} />
              <Route path="/novosti/:slug" element={<NewsDetails />} />

              {/* CONTACT */}
              <Route path="/kontakt" element={<Contact />} />
              {/* GALLERY */}
              <Route path="/galerija" element={<Gallery />} />
              {/* PRIVACY POLICY & TERMS OF USE */}
              <Route path="/politika-privatnosti" element={<PrivacyPolicy />} />
              <Route path="/uvjeti-korištenja" element={<TermsOfUse />} />
              {/* ERRORS */}
              <Route path="*" element={<NotFoundPage />} />
              <Route
                path="error"
                element={<ErrorPage error="Nepoznata greška" />}
              />
              {/* ADMIN */}
              <Route path="/admin/prijava" element={<Login />} />
              <Route
                path="/admin/dodaj-novost"
                element={
                  <PrivateRoute>
                    <AddNews />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/dodaj-utakmice-seniori"
                element={
                  <PrivateRoute>
                    <AddMatches />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/dodaj-ostale-utakmice"
                element={
                  <PrivateRoute>
                    <AddSelections />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/dashboard"
                element={
                  <PrivateRoute>
                    <AdminDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/dodaj-galeriju"
                element={
                  <PrivateRoute>
                    <AddGallery />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/dodaj-strijelce"
                element={
                  <PrivateRoute>
                    <AddPlayers />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/uredi-tablicu"
                element={
                  <PrivateRoute>
                    <AddStandings />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/uredi-treninge"
                element={
                  <PrivateRoute>
                    <AddTrainings />
                  </PrivateRoute>
                }
              />
            </Routes>
            <Sponsors />
            <Footer />
          </>
        )}
      </BrowserRouter>
    </AuthProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
