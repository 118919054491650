import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import Main from "../../Pages/Home/Main";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import { useNavigate } from "react-router-dom";

export default function OstaloSeason() {
  const [matches, setMatches] = useState([]); // Držimo spremljene utakmice
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "ostaleSelekcije"));
        const matchList = [];

        querySnapshot.forEach((doc) => {
          const matchData = { id: doc.id, ...doc.data() };
          matchList.push(matchData);
        });

        setMatches(matchList);
      } catch (error) {
        console.error("Greška pri učitavanju utakmica:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, []); // Učitaj podatke samo pri učitavanju komponente

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="OSTALE UTAKMICE"
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => navigate("/admin/dashboard")}
        >
          Vrati se nazad
        </button>
        {loading ? (
          <p>Učitavanje...</p>
        ) : matches.length === 0 ? (
          <div className="info-box">
            <p>Trenutno nema odigranih utakmica!</p>
            <span>NK Podsused</span>
          </div>
        ) : (
          <ul>
            {matches.map((match) => (
              <li key={match.id} className="match-info">
                <h3 className="selection-category">{match.category}</h3>{" "}
                {/* Prikaz selekcije */}
                <div className="match-result">
                  <div className="team-info">
                    <h3 className="team-name">{match.homeTeam}</h3>
                  </div>
                  <div className="final-result">
                    <h2>{match.result}</h2>
                  </div>
                  <div className="team-info">
                    <h3 className="team-name">{match.awayTeam}</h3>
                  </div>
                </div>
                <div>
                  <h5>
                    <i className="fi fi-rs-calendar-days"></i>
                    {match.date} - {match.time}
                  </h5>
                  <h5>
                    <i className="fi fi-rs-marker"></i>
                    {match.location}
                  </h5>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}
