import React, { useEffect, useState } from "react";
// Images
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

// Components
import Matches from "../Home/Matches";
import Main from "../Home/Main";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebaseConfig";

export default function Season() {
  const [selectedRound, setSelectedRound] = useState("5. KOLO"); // Postavi na posljednje kolo
  const [matches, setMatches] = useState([]);
  const [rounds, setRounds] = useState([]); // Držanje svih kola

  useEffect(() => {
    document.title = "NK Podsused | Seniori Sezona 2023/2024";

    const fetchMatches = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "utakmiceSeniori"));
        const matchList = [];
        const uniqueRounds = new Set(); // Skup za jedinstvena kola

        querySnapshot.forEach((doc) => {
          const matchData = { id: doc.id, ...doc.data() };
          matchList.push(matchData);
          uniqueRounds.add(matchData.round); // Dodavanje kola u skup
        });

        // Sortiraj utakmice tako da najnovije kolo bude prvo
        matchList.sort((a, b) => {
          const roundA = parseInt(a.round.split(".")[0]);
          const roundB = parseInt(b.round.split(".")[0]);
          return roundB - roundA; // Sortiranje silazno
        });

        setMatches(matchList);
        setRounds(Array.from(uniqueRounds)); // Postavi jedinstvena kola
      } catch (error) {
        console.error("Error fetching matches:", error);
      }
    };

    fetchMatches();
  }, []);

  const scrollToMatch = (kolo) => {
    const matchId = `match-${kolo}`;
    const matchElement = document.getElementById(matchId);

    if (matchElement) {
      const scrollYOffset =
        matchElement.getBoundingClientRect().top - window.innerHeight / 2;
      window.scrollBy({ top: scrollYOffset, behavior: "smooth" });
    }
  };

  const handleSelectChange = (e) => {
    const selectedKolo = e.target.value;
    setSelectedRound(selectedKolo);
    scrollToMatch(selectedKolo);
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
      />
      <div className="section">
        <select
          className="season-search"
          value={selectedRound}
          onChange={handleSelectChange}
        >
          {rounds.map((round) => (
            <option key={round} value={round}>
              {round}
            </option>
          ))}
        </select>
        <div className="season-matches-top">
          {/* Prikaz svih utakmica */}
          {matches.map((match) => (
            <div key={match.id} id={`match-${match.round}`}>
              <Matches matches={[match]} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
