import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import Main from "../../Pages/Home/Main";

export default function Login() {
  const [email, setEmail] = useState(""); // Update to email state
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await login(email, password); // Use the login method with email and password
      navigate("/admin/dashboard");
    } catch (error) {
      setError(
        "Neispravno korisničko ime ili lozinka. Molimo pokušajte ponovno."
      );
    }
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title=""
      />

      <div className="section">
        <h2>ADMIN PRIJAVA</h2>
        <form className="login-box" onSubmit={handleLogin}>
          <div>
            <label htmlFor="email">Email:</label> {/* Change to email */}
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password">Lozinka:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <button type="submit">Prijavi se</button>
        </form>
      </div>
    </>
  );
}
