import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import Main from "../../Pages/Home/Main";
// Slike
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

const categories = [
  { label: "Veterani", value: "Veterani" },
  { label: "Seniori", value: "Seniori" },
  { label: "Kadeti U16/U17", value: "Kadeti" },
  { label: "Pioniri U14/U15", value: "Pioniri" },
  { label: "Mlađi Pioniri U12/U13", value: "Mladi Pioniri" },
  { label: "Limači U10/U11", value: "Limaci" },
  { label: "Zagići U08/U09", value: "Zagici" },
];

export default function AddSelections() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [homeTeam, setHomeTeam] = useState("");
  const [awayTeam, setAwayTeam] = useState("");
  const [result, setResult] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [existingMatchId, setExistingMatchId] = useState(null); // ID za postojeću utakmicu
  const [matches, setMatches] = useState([]); // Držimo spremljene utakmice
  const [notification, setNotification] = useState(""); // Stanje za obaveštenje
  const navigate = useNavigate();

  // Ref za inpute vremena i datuma
  const timeInputRef = useRef(null);
  const dateInputRef = useRef(null);

  const handleCategorySelect = (event) => {
    setSelectedCategory(event.target.value);
    clearFields();
  };

  const clearFields = () => {
    setHomeTeam("");
    setAwayTeam("");
    setResult("");
    setTime("");
    setDate("");
    setLocation("");
    setExistingMatchId(null);
    setNotification(""); // Očisti obaveštenje
  };

  // Funkcija koja dohvata postojeće utakmice prilikom odabira selekcije
  useEffect(() => {
    const fetchExistingMatch = async () => {
      if (selectedCategory) {
        const collectionRef = collection(db, "ostaleSelekcije");
        const q = query(
          collectionRef,
          where("category", "==", selectedCategory)
        );

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const matchData = querySnapshot.docs[0];
          setExistingMatchId(matchData.id); // Postavi ID postojeće utakmice
          setHomeTeam(matchData.data().homeTeam);
          setAwayTeam(matchData.data().awayTeam);
          setResult(matchData.data().result);
          setTime(matchData.data().time);
          setDate(matchData.data().date);
          setLocation(matchData.data().location);
        }
      }
    };

    fetchExistingMatch();
  }, [selectedCategory]);

  // Funkcija koja dohvata spremljene utakmice iz Firebase
  const fetchMatches = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "ostaleSelekcije"));
      const matchList = [];
      querySnapshot.forEach((doc) => {
        const matchData = { id: doc.id, ...doc.data() };
        matchList.push(matchData);
      });
      setMatches(matchList);
    } catch (error) {
      console.error("Greška pri učitavanju utakmica:", error);
    }
  };

  useEffect(() => {
    fetchMatches(); // Učitaj spremljene utakmice prilikom učitavanja komponente
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!homeTeam || !awayTeam || !result || !time || !date || !location) {
      setNotification("Molimo unesite sve podatke!");
      return;
    }

    const collectionRef = collection(db, "ostaleSelekcije");

    try {
      if (existingMatchId) {
        // Ažuriranje postojećih podataka
        const matchRef = doc(collectionRef, existingMatchId);
        await updateDoc(matchRef, {
          homeTeam,
          awayTeam,
          result,
          time,
          date,
          location,
        });

        setNotification("Utakmica uspješno ažurirana!"); // Postavi poruku
      } else {
        // Pre nego što dodamo novu utakmicu, proveri da li već postoji
        const querySnapshot = await getDocs(
          query(collectionRef, where("category", "==", selectedCategory))
        );

        if (querySnapshot.empty) {
          // Ako ne postoji, dodaj novu utakmicu
          await addDoc(collectionRef, {
            category: selectedCategory,
            homeTeam,
            awayTeam,
            result,
            time,
            date,
            location,
          });

          setNotification("Utakmica uspješno dodana!");
        } else {
          setNotification(
            "Utakmica sa ovom kategorijom već postoji! Ažurirajte postojeću utakmicu."
          );
        }
      }

      clearFields();
      fetchMatches(); // Ponovo učitaj utakmice nakon dodavanja/ ažuriranja
    } catch (error) {
      setNotification("Greška prilikom dodavanja/ ažuriranja utakmice.");
    }
  };

  const handleCancel = () => {
    clearFields();
    setSelectedCategory(""); // Sakrij formu
  };

  const openTimePicker = () => {
    if (timeInputRef.current) {
      timeInputRef.current.focus();
    }
  };

  const openDatePicker = () => {
    if (dateInputRef.current) {
      dateInputRef.current.focus();
    }
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="DODAJ UTAKMICE"
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => {
            window.scrollTo(0, 0);
            navigate("/admin/dashboard");
          }}
        >
          Vrati se nazad
        </button>
        <div className="form">
          <select value={selectedCategory} onChange={handleCategorySelect}>
            <option value="" disabled>
              Odaberite selekciju
            </option>
            {categories.map((cat) => (
              <option key={cat.value} value={cat.value}>
                {cat.label}
              </option>
            ))}
          </select>
        </div>

        {selectedCategory && (
          <form className="addMatches-form" onSubmit={handleSubmit}>
            <h3>Dodaj utakmicu za {selectedCategory}</h3>
            <div className="club-flex">
              <input
                type="text"
                placeholder="Domaći Klub"
                value={homeTeam}
                onChange={(e) => setHomeTeam(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Gostujući Klub"
                value={awayTeam}
                onChange={(e) => setAwayTeam(e.target.value)}
                required
              />
            </div>
            <input
              type="text"
              placeholder="Rezultat (npr. 2:1)"
              value={result}
              onChange={(e) => setResult(e.target.value)}
              required
            />
            <input
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              onClick={openTimePicker} // Otvori biranje za vreme
              ref={timeInputRef} // Dodaj ref
              required
            />
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              onClick={openDatePicker} // Otvori biranje za datum
              ref={dateInputRef} // Dodaj ref
              required
            />
            <input
              type="text"
              placeholder="Lokacija"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
            />
            <button className="add-btn" type="submit">
              Spremi
            </button>
            <button className="remove-btn" type="button" onClick={handleCancel}>
              Odustani
            </button>
          </form>
        )}

        {/* Prikaz obaveštenja */}
        {notification && <div className="notification">{notification}</div>}

        {/* Prikaz spremljenih utakmica */}
        <div>
          <h2>Prikaz spremljenih utakmica</h2>
          {matches.length === 0 ? (
            <div className="info-box">
              <p>Trenutno nema spremljenih utakmica.</p>
              <span>NK Podsused</span>
            </div>
          ) : (
            <ul>
              {matches.map((match) => (
                <li key={match.id} className="match-info">
                  <h3 className="selection-category">{match.category}</h3>
                  {/* Prikaz selekcije */}
                  <div className="match-result">
                    <div className="team-info">
                      <h3 className="team-name">{match.homeTeam}</h3>
                    </div>
                    <div className="final-result">
                      <h2>{match.result}</h2>
                    </div>
                    <div className="team-info">
                      <h3 className="team-name">{match.awayTeam}</h3>
                    </div>
                  </div>
                  <div>
                    <h5>
                      <i className="fi fi-rs-calendar-days"></i>
                      {match.date} - {match.time}
                    </h5>
                    <h5>
                      <i className="fi fi-rs-marker"></i>
                      {match.location}
                    </h5>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}
