import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import Main from "../../Pages/Home/Main";
// Slike
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

const AddStandings = () => {
  const [standingsData, setStandingsData] = useState([]);
  const [editingTeam, setEditingTeam] = useState(null);
  const [updatedValues, setUpdatedValues] = useState({
    bod: "",
    raz: "",
    utk: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "tablica"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort teams by points and goal difference
        const sortedData = data.sort((a, b) => {
          if (parseInt(b.bod) !== parseInt(a.bod)) {
            return parseInt(b.bod) - parseInt(a.bod);
          }
          return parseInt(b.raz) - parseInt(a.raz);
        });

        setStandingsData(sortedData);
      } catch (error) {
        console.error("Error fetching standings data: ", error);
      }
    };

    fetchData();
  }, []);

  // Handle when a team is clicked for editing
  const handleEditClick = (team) => {
    setEditingTeam(team.id);
    setUpdatedValues({
      bod: team.bod,
      raz: team.raz,
      utk: team.utk,
    });
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Sort standings by points and goal difference
  const sortStandings = (data) => {
    return data.sort((a, b) => {
      if (parseInt(b.bod) !== parseInt(a.bod)) {
        return parseInt(b.bod) - parseInt(a.bod);
      }
      return parseInt(b.raz) - parseInt(a.raz);
    });
  };

  const handleSaveClick = async (teamId) => {
    const teamRef = doc(db, "tablica", teamId);

    try {
      await updateDoc(teamRef, {
        bod: parseInt(updatedValues.bod),
        raz: parseInt(updatedValues.raz),
        utk: parseInt(updatedValues.utk),
      });

      // Update local state
      const updatedStandings = standingsData.map((team) =>
        team.id === teamId
          ? {
              ...team,
              bod: updatedValues.bod,
              raz: updatedValues.raz,
              utk: updatedValues.utk,
            }
          : team
      );

      const sortedStandings = sortStandings(updatedStandings);
      setStandingsData(sortedStandings);
      setEditingTeam(null);
    } catch (error) {
      console.error("Error updating team data: ", error);
    }
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="UREDI TABLICU"
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => navigate("/admin/dashboard")}
        >
          Vrati se nazad
        </button>
        <ul className="table table-six">
          {/* Header row */}
          <li className="row">
            <h5>#</h5>
            <h5>Klub</h5>
            <h5>Ut.</h5>
            <h5>G +/-</h5>
            <h5>Bod</h5>
          </li>
          {/* List of teams */}
          {standingsData.map((team, index) => {
            const rowClass = team.klub.includes("NK Podsused")
              ? "row podsused"
              : "row";

            return (
              <li key={team.id} className={rowClass}>
                {editingTeam === team.id ? (
                  <>
                    <h5>{index + 1}</h5>
                    <p>
                      <img src={team.logoUrl} alt={`${team.klub} logo`} />
                      {team.klub}
                    </p>
                    <input
                      type="number"
                      name="utk"
                      value={updatedValues.utk}
                      onChange={handleInputChange}
                      placeholder="Ut."
                    />
                    <input
                      type="number"
                      name="raz"
                      value={updatedValues.raz}
                      onChange={handleInputChange}
                      placeholder="G +/-"
                    />
                    <input
                      type="number"
                      name="bod"
                      value={updatedValues.bod}
                      onChange={handleInputChange}
                      placeholder="Bod"
                    />
                    <button
                      className="add-btn"
                      onClick={() => handleSaveClick(team.id)}
                    >
                      Spremi
                    </button>
                  </>
                ) : (
                  <>
                    <h5>{index + 1}</h5>
                    <p>
                      <img src={team.logoUrl} alt={`${team.klub} logo`} />
                      {team.klub}
                    </p>
                    <p>{team.utk}</p>
                    <p>{team.raz}</p>
                    <p>{team.bod}</p>
                    <button
                      className="add-btn"
                      onClick={() => handleEditClick(team)}
                    >
                      Uredi
                    </button>
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default AddStandings;
