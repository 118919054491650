import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import Main from "../../Pages/Home/Main";
import { useNavigate } from "react-router-dom";

// Images
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

// Trening Component
function Trening({
  selection,
  days,
  trainerName,
  trainerContact,
  onEdit,
  onRemove,
}) {
  return (
    <div className="trening">
      <div className="trening-grid">
        <h2>{selection}</h2>
        {days.map((dayInfo, i) => (
          <div className="trening-box" key={i}>
            <h4>{dayInfo.day}</h4>
            <p>{dayInfo.time}</p>
          </div>
        ))}
      </div>
      <div className="trening-info">
        <h5>TRENER: {trainerName}</h5>
        <a href={`tel:${trainerContact}`}>
          <i className="fi fi-rs-phone-flip"></i> {trainerContact}
        </a>
        <button className="add-btn" onClick={onEdit}>
          Uredi
        </button>
        <button className="remove-btn" onClick={onRemove}>
          Ukloni
        </button>
      </div>
    </div>
  );
}

export default function AddTrainings() {
  const [trainings, setTrainings] = useState([]);
  const [selection, setSelection] = useState("");
  const [trainerName, setTrainerName] = useState("");
  const [trainerContact, setTrainerContact] = useState("");
  const [days, setDays] = useState([{ day: "", time: "" }]);
  const [editTrainingId, setEditTrainingId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(""); // To store success/error messages
  const navigate = useNavigate();

  // Fetch existing trainings from Firebase
  const fetchTrainings = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, "trening"));
      const fetchedTrainings = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setTrainings(fetchedTrainings);
    } catch (error) {
      setMessage("Error fetching trainings.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTrainings();
  }, []);

  const handleAddTraining = async () => {
    if (!validateForm()) return; // Validate the form before proceeding

    try {
      setLoading(true);
      if (editTrainingId) {
        await updateDoc(doc(db, "trening", editTrainingId), {
          selection,
          days,
          trainerName,
          trainerContact,
        });
        setMessage("Trening uspješno ažuriran!");
      } else {
        const newTraining = {
          selection,
          days,
          trainerName,
          trainerContact,
        };
        await addDoc(collection(db, "trening"), newTraining);
        setMessage("Trening uspješno dodan!");
      }

      fetchTrainings();
      clearForm();
    } catch (error) {
      setMessage("Greška pri dodavanju/ažuriranju treninga.");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveTraining = async (id) => {
    try {
      setLoading(true);
      await deleteDoc(doc(db, "trening", id));
      setTrainings(trainings.filter((training) => training.id !== id));
      setMessage("Trening uspješno obrisan");
    } catch (error) {
      setMessage("Greška prilikom brisanja treninga.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditTraining = (training) => {
    setSelection(training.selection);
    setTrainerName(training.trainerName);
    setTrainerContact(training.trainerContact);
    setDays(training.days);
    setEditTrainingId(training.id);
  };

  const clearForm = () => {
    setSelection("");
    setTrainerName("");
    setTrainerContact("");
    setDays([{ day: "", time: "" }]);
    setEditTrainingId(null);
    setMessage(""); // Clear message on form reset
  };

  const validateForm = () => {
    if (
      !selection ||
      !trainerName ||
      !trainerContact ||
      !days.every((d) => d.day && d.time)
    ) {
      alert("Molimo popunite sva polja.");
      return false;
    }

    // Simple phone number validation (adjust as needed)
    const phoneRegex = /^[0-9]+$/; // Basic check for numbers only
    if (!phoneRegex.test(trainerContact)) {
      alert("Molim upišite točan broj.");
      return false;
    }

    return true;
  };

  // Add another training day
  const handleAddDay = () => {
    if (days.length >= 3) {
      alert("Maksimalno možete dodati 3 dana.");
      return;
    }
    setDays([...days, { day: "", time: "" }]);
  };

  // Remove a specific day from the days array
  const handleRemoveDay = (index) => {
    const newDays = [...days];
    newDays.splice(index, 1);
    setDays(newDays);
  };

  // Update day or time in the days array
  const handleDayChange = (index, value) => {
    const newDays = [...days];
    newDays[index].day = value;
    setDays(newDays);
  };

  const handleTimeChange = (index, value) => {
    const newDays = [...days];
    newDays[index].time = value;
    setDays(newDays);
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="UREDI TRENINGE"
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => navigate("/admin/dashboard")}
        >
          Vrati se nazad
        </button>

        {loading && <p>Loading...</p>}
        {message && <p className="message">{message}</p>}

        {/* Form Fields */}
        <div className="form">
          <label>Odaberi selekciju</label>
          <select
            value={selection}
            onChange={(e) => setSelection(e.target.value)}
          >
            <option value="">Odaberi selekciju</option>
            <option value="Seniori">Seniori</option>
            <option value="Juniori">Juniori</option>
            <option value="Kadeti">Kadeti</option>
            <option value="Pioniri">Pioniri</option>
            <option value="Limači">Limači</option>
            <option value="Zagići">Zagići</option>
          </select>

          <label>Ime trenera</label>
          <input
            type="text"
            value={trainerName}
            onChange={(e) => setTrainerName(e.target.value)}
            placeholder="Unesi ime trenera"
          />

          <label>Kontakt broj trenera</label>
          <input
            type="text"
            value={trainerContact}
            onChange={(e) => setTrainerContact(e.target.value)}
            placeholder="Unesi broj trenera"
          />
        </div>

        {/* Multiple Days */}
        {days.map((dayObj, index) => (
          <div key={index} className="form remove-margin">
            <label>Odaberi dan</label>
            <select
              value={dayObj.day}
              onChange={(e) => handleDayChange(index, e.target.value)}
            >
              <option value="">Odaberi dan</option>
              <option value="Ponedjeljak">Ponedjeljak</option>
              <option value="Utorak">Utorak</option>
              <option value="Srijeda">Srijeda</option>
              <option value="Četvrtak">Četvrtak</option>
              <option value="Petak">Petak</option>
              <option value="Subota">Subota</option>
            </select>

            <input
              type="text"
              value={dayObj.time}
              onChange={(e) => handleTimeChange(index, e.target.value)}
              placeholder="Unesi vrijeme (npr. 18:00)"
            />

            {/* Remove Day Button */}
            <button
              className="remove-btn"
              onClick={() => handleRemoveDay(index)}
            >
              Ukloni dan
            </button>
          </div>
        ))}

        <div className="form remove-margin">
          {days.length < 3 && (
            <button className="add-btn" onClick={handleAddDay}>
              Dodaj trening
            </button>
          )}
        </div>

        <div className="form remove-margin">
          <button className="add-btn publish" onClick={handleAddTraining}>
            Spremi / Ažuriraj Trening
          </button>
        </div>
        {/* List of Added Trainings */}
        <h4 className="center">Prikaz spremljenih treninga:</h4>
        {trainings.length > 0 ? (
          <ul className="raspored-treninga">
            {trainings.map((training) => (
              <li key={training.id}>
                <Trening
                  selection={training.selection}
                  days={training.days}
                  trainerName={training.trainerName}
                  trainerContact={training.trainerContact}
                  onEdit={() => handleEditTraining(training)}
                  onRemove={() => handleRemoveTraining(training.id)}
                />
              </li>
            ))}
          </ul>
        ) : (
          <div className="info-box">
            <p>Trenutno nema spremljenih treninga.</p>
            <span>NK Podsused</span>
          </div>
        )}
      </div>
    </>
  );
}
