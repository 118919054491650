import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import Ball from "../../../Assets/Images/Ostalo/Ball.png";
import YellowCard from "../../../Assets/Images/Ostalo/YellowCard.png";
import RedCard from "../../../Assets/Images/Ostalo/RedCard.png";
import Postave from "../../../Assets/Images/Ostalo/Postave.png";
import Player from "../../../Assets/Images/Ostalo/Player.png";

export default function Matches({ matches }) {
  const [logos, setLogos] = useState({}); // Držanje URL-ova logotipa
  const [loading, setLoading] = useState(true);
  const [showDetails, setShowDetails] = useState({}); // Držanje stanja detalja po utakmici

  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const clubsCollection = collection(db, "klubovi"); // Kolekcija za klubove
        const clubSnapshot = await getDocs(clubsCollection);
        const clubList = {};
        clubSnapshot.forEach((doc) => {
          const data = doc.data();
          clubList[data.name] = data.logoUrl; // Mapiraj ime kluba na logoUrl
        });
        setLogos(clubList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching logos: ", error);
      }
    };

    fetchLogos();
  }, []);

  const toggleDetails = (matchId) => {
    setShowDetails((prev) => ({
      ...prev,
      [matchId]: !prev[matchId],
    }));
  };

  if (loading) {
    return <div>Učitavanje...</div>;
  }

  return (
    <>
      {matches.map((match) => (
        <div key={match.id} id={`match-${match.round}`} className="match-info">
          <h2>{match.round}</h2> {/* Prikaz runde */}
          <div className="match-result">
            <div className="slide-left team-info">
              <h3 className="team-name">{match.homeTeam}</h3>
              {logos[match.homeTeam] && ( // Dohvati logo domaćeg tima iz mape
                <img
                  className="match-team-logo"
                  src={logos[match.homeTeam]} // Koristi URL logotipa
                  alt={match.homeTeam}
                  loading="lazy"
                />
              )}
            </div>
            <div className="final-result">
              <h2>{match.result}</h2>
            </div>
            <div className="slide-right team-info">
              <h3 className="team-name">{match.awayTeam}</h3>
              {logos[match.awayTeam] && ( // Dohvati logo gostujućeg tima iz mape
                <img
                  className="match-team-logo"
                  src={logos[match.awayTeam]} // Koristi URL logotipa
                  alt={match.awayTeam}
                  loading="lazy"
                />
              )}
            </div>
          </div>
          <h5>
            <i className="fi fi-rs-calendar-days"></i>
            {match.date} - {match.time}
          </h5>
          <h5>
            <i className="fi fi-rs-marker"></i>
            {match.location}
          </h5>
          <div className="match-details">
            {showDetails[match.id] && (
              <>
                {match.events && match.events.length > 0 && (
                  <Details details={match.events} />
                )}
                {match.lineup && match.lineup.length > 0 && (
                  <Lineups
                    players={match.lineup}
                    benchPlayers={match.benchPlayers}
                  />
                )}
              </>
            )}
          </div>
          {(match.events?.length > 0 || match.lineup?.length > 0) && (
            <button
              className="details-btn"
              onClick={() => toggleDetails(match.id)}
            >
              {showDetails[match.id] ? "Prikaži manje" : "Detalji sa utakmice"}
            </button>
          )}
        </div>
      ))}
    </>
  );
}

function Details(props) {
  const renderEventImage = (eventType) => {
    switch (eventType) {
      case "Gol":
        return <img src={Ball} alt="Goal" loading="lazy" />;
      case "Žuti karton":
        return <img src={YellowCard} alt="Yellow Card" loading="lazy" />;
      case "Crveni karton":
        return <img src={RedCard} alt="Red Card" loading="lazy" />;
      default:
        return null;
    }
  };

  return (
    <>
      <ul>
        {props.details.map((detail, index) => (
          <li className="details" key={index}>
            {detail.team === "Domaći" && ( // Provjera za domaći tim
              <span className="left">
                {detail.player} {renderEventImage(detail.type)} {detail.time}’
              </span>
            )}

            {detail.team === "Gostujući" && ( // Provjera za gostujući tim
              <span className="right">
                {detail.time}’ {renderEventImage(detail.type)} {detail.player}
              </span>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}

function Lineups(props) {
  return (
    <>
      <div className="lineups">
        <div className="lineups-stadium">
          <img src={Postave} alt="Teren" loading="lazy" />
          <div className="players">
            {props.players &&
              props.players.length > 0 &&
              props.players.map((player, index) => (
                <div key={index} className={`player ${player.position}`}>
                  <img
                    src={Player}
                    alt={player.player}
                    className="player-img"
                    loading="lazy"
                  />
                  <span>{player.player}</span>
                </div>
              ))}
          </div>
        </div>
        <div className="bench-players">
          {props.benchPlayers &&
            props.benchPlayers.length > 0 &&
            props.benchPlayers.map((player, index) => (
              <div key={index} className={`player ${player.pos}`}>
                <img
                  src={Player}
                  alt={player.name}
                  className="player-img"
                  loading="lazy"
                />
                <h6>{player.name}</h6>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
