import React, { createContext, useState, useContext, useEffect } from "react";
import { auth } from "../../../firebaseConfig";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

// Create AuthContext
const AuthContext = createContext();

// Custom hook for easier access to AuthContext
export const useAuth = () => useContext(AuthContext);

// Export AuthProvider as a named export (not default)
export default function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Monitor authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user); // Set auth state based on user presence
    });

    return () => unsubscribe(); // Clean up the subscription
  }, []);

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem("isAuthenticated", true); // Optionally store the auth state
    } catch (error) {
      console.error("Error logging in: ", error);
      throw new Error(error.message);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth); // Sign out from Firebase
      localStorage.removeItem("isAuthenticated"); // Remove the auth state from local storage
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
