import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";

const News = ({ slug }) => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "novosti"));
        const newsList = [];
        querySnapshot.forEach((doc) => {
          newsList.push({ id: doc.id, ...doc.data() });
        });
        setNewsData(newsList);
      } catch (error) {
        console.error("Error fetching news data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNewsData();
  }, []);

  // Funkcija za formatiranje datuma
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const news = slug ? newsData.find((item) => item.slug === slug) : null;

  if (loading) {
    return <div>Učitavanje...</div>;
  }

  if (!news) {
    return <div>Novost nije pronađena!</div>;
  }

  return (
    <li className="novosti-box">
      <Link to={`/novosti/${news.slug}`}>
        <div className="novosti-img">
          {news.mainImage ? (
            <img
              src={news.mainImage}
              alt={news.imageAlt}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
          ) : (
            <div>Slika nije dostupna.</div>
          )}
          <div className="novosti-btn">Više...</div>
        </div>
        <span>#{news.category}</span>
        <h4>{news.title || "Naslov nije dostupan"}</h4>
        <span>{formatDate(news.date)}</span>{" "}
        {/* Prikazuje datum u formatu dd/mm/yyyy */}
      </Link>
    </li>
  );
};

export default News;
