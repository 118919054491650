import React, { useState, useEffect } from "react";
import { db } from "../../../firebaseConfig"; // Import Firebase config
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import Player from "../../../Assets/Images/Ostalo/Player.png";
import Ball from "../../../Assets/Images/Ostalo/Ball.png";

// Component to display each player row
const TeamRow = ({ player, index }) => {
  return (
    <li className="row">
      <h5>{index}</h5>
      <p>
        <img src={Player} alt={player.name} />
        {player.name}
      </p>
      <p>
        {player.goals} <img src={Ball} alt="Ball" />
      </p>
    </li>
  );
};

// Component to display the player standings
const PlayersStatistics = ({ standingsData }) => {
  return (
    <ul className="statistics">
      <li className="row">
        <h5>#</h5>
        <h5>Strijelac</h5>
        <h5>Golovi</h5>
      </li>
      {standingsData.map((player, index) => (
        <TeamRow key={index} player={player} index={index + 1} />
      ))}
    </ul>
  );
};

// Main component for showing statistics and managing players
export default function Statistics() {
  const [playerStats, setPlayerStats] = useState([]); // Initial empty player list

  // Fetch players from Firebase Firestore
  useEffect(() => {
    const playersCollectionRef = collection(db, "strijelci");
    const q = query(playersCollectionRef, orderBy("goals", "desc")); // Query to order by goals descending

    // Set up real-time subscription to Firestore
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const playersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPlayerStats(playersData);
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <>
      {/* Show only the player statistics */}
      <PlayersStatistics standingsData={playerStats} />
    </>
  );
}
