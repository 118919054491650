import React, { useState, useEffect } from "react";
import { db } from "../../../firebaseConfig";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Main from "../../Pages/Home/Main";
// Slike
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import Ball from "../../../Assets/Images/Ostalo/Ball.png";

// List of available players
const playersList = [
  "G. Bašić",
  "T. Ivanuš",
  "A. Boras",
  "K. Borko",
  "M. Perković",
  "K. Minković",
  "R. Budiselić",
  "M. Stunja",
  "M. Vodopija",
  "D. Mihoković",
  "M. Sedmak",
  "C | J. Gotal",
  "M. Knežević",
  "L. Moguljak",
  "K. Mihelčić",
  "S. Miloš",
  "J. Jambrek",
  "J. Mesić",
  "L. Ivčić",
  "J. Jurić",
  "T. Posavec",
  "F. Đuričić",
  "L. Nerat",
];

export default function AddPlayers({ updatePlayers }) {
  const [availablePlayers, setAvailablePlayers] = useState(playersList);
  const [selectedPlayer, setSelectedPlayer] = useState("");
  const [goals, setGoals] = useState(0);
  const [addedPlayers, setAddedPlayers] = useState([]); // Keep track of added players
  const [showForm, setShowForm] = useState(false); // Show/hide form
  const [editingPlayer, setEditingPlayer] = useState(null); // Currently editing player
  const navigate = useNavigate();

  // Load players from Firestore when the component mounts
  useEffect(() => {
    const fetchPlayers = async () => {
      const querySnapshot = await getDocs(collection(db, "strijelci"));
      const players = [];
      querySnapshot.forEach((doc) => {
        players.push({ id: doc.id, ...doc.data() }); // Get player data and ID
      });
      setAddedPlayers(players); // Update the state with fetched players
    };

    fetchPlayers();
  }, []);

  const handleAddPlayer = async () => {
    if (selectedPlayer.trim() !== "" && goals > 0) {
      try {
        // Add player to Firebase
        const docRef = await addDoc(collection(db, "strijelci"), {
          name: selectedPlayer,
          goals: parseInt(goals),
        });
        console.log("Player added with ID: ", docRef.id);

        // Update the list of added players
        const newPlayer = {
          id: docRef.id,
          name: selectedPlayer,
          goals: parseInt(goals),
        };
        setAddedPlayers([...addedPlayers, newPlayer]);
        updatePlayers(newPlayer);

        // Remove added player from available players
        setAvailablePlayers(
          availablePlayers.filter((player) => player !== selectedPlayer)
        );

        // Reset inputs
        resetInputs();
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    } else {
      alert("Molimo odaberite igrača i unesite broj golova.");
    }
  };

  const resetInputs = () => {
    setSelectedPlayer("");
    setGoals(0);
    setShowForm(false); // Hide form after adding
    setEditingPlayer(null); // Reset editing state
  };

  const handleRemovePlayer = async (playerToRemove) => {
    try {
      // Remove player from Firestore
      await deleteDoc(doc(db, "strijelci", playerToRemove.id)); // Remove from Firestore
      setAddedPlayers(
        addedPlayers.filter((player) => player.id !== playerToRemove.id)
      );

      // Return player back to available
      setAvailablePlayers([...availablePlayers, playerToRemove.name]);
    } catch (error) {
      console.error("Error removing player: ", error);
    }
  };

  const handleEditPlayer = (player) => {
    setEditingPlayer(player); // Set player being edited
    setGoals(player.goals); // Set current goals in input
    setShowForm(false); // Hide add form when editing
  };

  const handleUpdatePlayer = async () => {
    try {
      const playerRef = doc(db, "strijelci", editingPlayer.id); // Get reference to the player in Firestore
      await updateDoc(playerRef, { goals: parseInt(goals) }); // Update player in Firestore

      // Update local state
      setAddedPlayers((prevPlayers) =>
        prevPlayers.map((player) =>
          player.id === editingPlayer.id
            ? { ...player, goals: parseInt(goals) }
            : player
        )
      );

      resetInputs(); // Reset fields and hide form
    } catch (error) {
      console.error("Error updating player: ", error);
    }
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="DODAJ STRIJELCE"
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => navigate("/admin/dashboard")}
        >
          Vrati se nazad
        </button>
        <div>
          {/* Button to show form */}
          {!showForm && !editingPlayer && (
            <button className="add-btn" onClick={() => setShowForm(true)}>
              Dodaj novog strijelca
            </button>
          )}
        </div>

        {/* Form for adding player */}
        {showForm && !editingPlayer && (
          <div className="addMatches-form">
            <label>Odaberi igrača:</label>
            <select
              value={selectedPlayer}
              onChange={(e) => setSelectedPlayer(e.target.value)}
            >
              <option value="">Odaberite igrača</option>
              {availablePlayers.map((player, index) => (
                <option key={index} value={player}>
                  {player}
                </option>
              ))}
            </select>
            <label>Unesi broj golova:</label>
            <input
              type="number"
              placeholder="Golovi"
              value={goals}
              onChange={(e) => setGoals(e.target.value)}
            />
            <button className="add-btn" onClick={handleAddPlayer}>
              Spremi
            </button>
            <button className="remove-btn" onClick={resetInputs}>
              Odustani
            </button>{" "}
            {/* Button to close form */}
          </div>
        )}

        {/* Form for editing player */}
        {editingPlayer && (
          <div className="edit-player-form">
            <p>Uređivanje igrača: {editingPlayer.name}</p>
            <label>Broj golova:</label>
            <input
              type="number"
              placeholder="Golovi"
              value={goals}
              onChange={(e) => setGoals(e.target.value)}
            />
            <button onClick={handleUpdatePlayer}>Spremi promjene</button>
            <button
              onClick={() => {
                resetInputs(); // Ensure form is hidden after canceling
              }}
            >
              Odustani
            </button>{" "}
            {/* Button to close form */}
          </div>
        )}

        {/* Display added players with edit and remove buttons */}
        {addedPlayers.length > 0 && (
          <ul className="added-players-list">
            {addedPlayers.map((player) => (
              <li key={player.id} className="added-player-item">
                <span>
                  {player.name} - {player.goals} <img src={Ball} alt="Lopta" />
                </span>
                <div className="button">
                  <button
                    className="add-btn"
                    onClick={() => handleEditPlayer(player)}
                  >
                    Uredi
                  </button>
                  <button
                    className="remove-btn"
                    onClick={() => handleRemovePlayer(player)}
                  >
                    Ukloni
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}
