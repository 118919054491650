import React, { useState } from "react";
import { storage, db } from "../../../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import Main from "../../Pages/Home/Main";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import { useNavigate } from "react-router-dom";

export default function AddGallery({ onImageAdded }) {
  const [images, setImages] = useState([]);
  const [author, setAuthor] = useState("");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState(""); // State for warning messages
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    if (e.target.files) {
      setImages(Array.from(e.target.files)); // Convert file list to array
    }
  };

  const handleUpload = async () => {
    if (images.length === 0 || !author) {
      setWarningMessage("Molim odaberite slike ili upišite autora."); // Set warning message
      setTimeout(() => {
        setWarningMessage("");
      }, 3000);
      return; // Exit function early
    }

    setUploading(true);
    let uploadedCount = 0;

    images.forEach((image) => {
      const storageRef = ref(storage, `galerija/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progressPercent =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progressPercent);
        },
        (error) => {
          console.error("Upload failed", error);
          setUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await addDoc(collection(db, "galerija"), {
            imageUrl: downloadURL,
            author: author,
          });

          uploadedCount++;
          if (uploadedCount === images.length) {
            setUploading(false);
            setImages([]);
            setAuthor("");
            setProgress(0);
            setShowSuccessMessage(true); // Show success message

            // Hide success message after 3 seconds
            setTimeout(() => {
              setShowSuccessMessage(false);
            }, 3000);

            if (onImageAdded) onImageAdded();
          }
        }
      );
    });
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="DODAJ GALERIJU"
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => {
            window.scrollTo(0, 0);
            navigate("/admin/dashboard");
          }}
        >
          Vrati se nazad
        </button>
        <h2>Dodaj novu sliku u galeriju</h2>
        <div className="form">
          <input
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            multiple
          />
          {warningMessage && (
            <div className="warning-message">{warningMessage}</div>
          )}
          <input
            type="text"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            placeholder="Autor slike"
          />
          {showSuccessMessage && (
            <div className="success-message">Slika objavljena!</div>
          )}
          <button
            className="add-btn"
            onClick={handleUpload}
            disabled={uploading}
          >
            {uploading ? `Učitavanje (${Math.round(progress)}%)` : "Objavi"}
          </button>
        </div>
      </div>
    </>
  );
}
